import { Dispatch, SetStateAction } from 'react';
import type {
  Audience, Filters, URL, SimpleUser, Attachment
} from '@common/types/objects';
import type { Screen, ScreenComponentItem } from '../learning/types/objects';
import { SliderProps }
  from '../../common/components/form/user-response-fields/wrappers/slider';
import { NumberScreenComponentValue }
  from '@common/components/form/user-response-fields/components/number';
import { DateTimeScreenValue }
  from '@common/components/form/user-response-fields/components/date-time';
import { ChecklistScreenComponentValue }
  from '@common/components/form/user-response-fields/components/checklist';
import { QuestionTextValue }
  from '@common/components/form/user-response-fields/components/question-text';
import { DropdownScreenComponentValue }
  from '@common/components/form/user-response-fields/components/dropdown';
import { BooleanScreenComponentValue }
  from '@common/components/form/user-response-fields/components/boolean';
import { FileUploadScreenComponentValue }
  from '@common/components/form/user-response-fields/components/file-upload';
import { Comment } from '@modules/social/types/objects';
import { EStatus } from '@common/definitions';

export type SubmissionStatus = {
  id: string;
  name: string;
  color: string;
};

export type APIQuestion = {
  description: string;
  id: string;
  settings: Record<string, any>; // TODO implement exact typing
  text: string;
  type: string; // TODO exact typing
};

export type APIScreen = {
  components: ScreenComponentItem[];
  id: string;
  index: number;
  is_published: boolean;
  question: APIQuestion | null;
  settings: Record<string, any>; // TODO exact typing
  type: 'screen';
};

export type APIForm = {
  type: 'form';
  id: string;
  status: EStatus;
  organisation_id: string;
  title: string;
  icon: URL;
  color: string;
  categories: string[];
  response_statuses: SubmissionStatus[];
  audience: Audience;
  screens: APIScreen[];
  settings: {
    show_as_shortcut: boolean;
    show_status_to_users: boolean;
    notify_user_of_status_updates: boolean;
    is_anonymous: boolean;
    moderators: {
      user_id: string;
      can_edit_content: boolean;
    }[];
  };
  responded_count: number;
  created_by: string;
  archived_at: string | null;
  deleted_at: string | null;
  updated_at: string;
  created_at: string;
};

export type FormStored = Omit<APIForm, 'created_by' | 'screens'> & {
  created_by: string;
  screen_ids?: string[];
};

export type Form = Omit<FormStored, 'created_by' | 'screen_ids'> & {
  filters: Filters;
  created_by: SimpleUser;
};

export type FormDetail = Form & {
  screens: Screen[];
  screen_ids?: string[];
};

export enum EFormSubmissionStatuses {
  PUBLISHED = 'published',
  DRAFT = 'draft'
}

export type FormSubmissionStatus = `${EFormSubmissionStatuses}`;

type APISubmissionAnswer = {
  question_id: string;
  value: string | string[] | number | boolean | null;
};

export type APIFormSubmission = {
  type: 'form_submission';
  answers: APISubmissionAnswer[];
  attachments: Attachment[];
  comments_count: number;
  id: string;
  index: number;
  organisationId: string;
  published_at: string;
  form: APIForm;
  response_status: {
    id: string;
    name: string;
    color: string;
  },
  submission_status: FormSubmissionStatus;
  timezone: {
    name: string;
    offset_to_gmit_in_seconds: number;
  };
  updated_at: string;
  user_id: string;
  user?: SimpleUser;
  created_at?: string;
};

export type FormSubmissionStored = APIFormSubmission;
export type FormSubmission = FormSubmissionStored;

export type AnswerValue = string | string[] | number | boolean;

export type FormStoredSubmissionAnswer = {
  question_id: string;
  value: AnswerValue; // TODO define value per question type
};

export type FormSubmissionAnswer = Omit<FormStoredSubmissionAnswer, 'question_id'> & {
  id: string;
  question: ScreenComponentItem;
  answer: AnswerValue | Attachment[];
};

export type Status = {
  id: string;
  name: string;
  color: string;
};

export type ResponseStatusActivity = {
  created_at: string;
  id: string;
  new_status: Status;
  old_status: Status | null;
  user_id: string;
};

export type APIFormSubmissionDetail = {
  id: string;
  answers: FormStoredSubmissionAnswer[];
  attachments: Attachment[],
  form: Form & {
    screens: Screen[];
  };
  user_id: string | null;
  response_status_activities: any;
};

export type FormSubmissionDetailStored = APIFormSubmissionDetail;

export type FormSubmissionDetail = Omit<FormSubmissionDetailStored, 'answers'> & {
  answers: FormSubmissionAnswer[];
  user: SimpleUser | null;
};

export type FormCounts = {
  archived_count: number;
  draft_count: number;
  live_count: number;
  total_count: number;
};

export type APIFormTemplate = {
  color: string;
  content_language_locale: string;
  created_at: string;
  highlighted: boolean;
  icon: string;
  id: string;
  industry_types: null | string[];
  organisation_id: string;
  response_statuses: SubmissionStatus[];
  settings: Form['settings'],
  title: string;
  type: 'form_template';
  updated_at: string;
};

export type FormTemplateStored = APIFormTemplate;

export type APIFormTemplateDetail = APIFormTemplate & {
  screens: Screen[];
};

export type FormTemplateDetailStored = APIFormTemplateDetail;

export type FormSubmissionFieldValue = (
  SliderProps['value'] | NumberScreenComponentValue | DateTimeScreenValue |
  DateTimeScreenValue | ChecklistScreenComponentValue |
  QuestionTextValue | DropdownScreenComponentValue |
  BooleanScreenComponentValue | FileUploadScreenComponentValue
);

export type FormSubmissionValues = Record<string, FormSubmissionFieldValue>;

export type SetFormSubmissionValues = Dispatch<SetStateAction<FormSubmissionValues>>;

export type FormSubmissionComment = Comment & {
  user_id: string;
};

export type FormattedFormSubmittionComment = FormSubmissionComment & {
  user: SimpleUser;
};

export type FormSubmissionConfig = {
  id: string;
  actions: {
    can_delete_comments: boolean;
  };
};

export type GetFormSubmissionsCommentsMeta = {
  related: {
    users: SimpleUser[];
  };
};
